import React from "react"
import Layout from "../components/layout"
import AgenciesMarquee from "../components/agencies-marquee"
import ProductHero from "../components/product-hero"
import CaseStudiesSection from "../components/case-studies-section"
import SEO from "../components/seo"
import RequestDemoSection from "../components/request-demo-section"

const CaseStudiesPage = () => (
  <Layout>
    <SEO
      title="inPowered Case Studies"
      description="Learn how brands across various industries are winning with inPowered's AI platform."
    />
    <ProductHero title={["Client Success Stories"]} />
    <div className="px-3">
      <CaseStudiesSection />
    </div>
    <RequestDemoSection formPlacement="Case Studies List" />
    <AgenciesMarquee />
  </Layout>
)

export default CaseStudiesPage
